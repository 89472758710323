import firebase from "firebase/compat";
import router from "../router";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";

export default {
    actions: {
        async LOGIN ({commit}, data) {
            try {
                const auth = getAuth();
                setPersistence(auth, browserSessionPersistence)
                  .then(() => {
                      // https://firebase.google.com/docs/auth/web/auth-state-persistence
                      return signInWithEmailAndPassword(auth, data.login, data.pass).then(res => {
                                  commit('SET_USER_AUTH', res.user.uid)
                                  localStorage.user = res.user.uid
                                  router.replace('/')
                              })
                  })
                  .catch((error) => {
                      console.log(error)
                  });
            }
            catch (e) {
                return e
            }
        },
        async LOGOUT ({commit}) {
            await firebase.auth().signOut()
            commit('LOGOUT')
        }
    }
}
