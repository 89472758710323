import {getDatabase, ref, set} from "firebase/database";
import firebase from "firebase/compat";

export default {
    async writeUserData(data) {
        // console.log('сохранение...')
        // const db = getDatabase();
        // await set(ref(db, '/'), {
        //     data
        // })
        await firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                console.log('сохранение...')
                const db = getDatabase();
                set(ref(db, '/'), {
                    data
                });
            } else {
                alert('Сессия устарела, перезайдите в профиль администратора')
            }
            // ...
        })
    },
    async writePointsData(url, index, data) {
        await firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                console.log('сохранение...')
                const db = getDatabase();
                set(ref(db, '/data/courses/points/' + index), {
                  content: data.content,
                  id: data.id,
                  name: data.name,
                  priceContent: data.priceContent,
                  tags: data.tags,
                  image: url
                });
            } else {
                alert('Сессия устарела, перезайдите в профиль администратора')
            }
            // ...
        })
    },
    async writeYouTubeUrl(url, index, data) {
        await firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                console.log('сохранение...')
                const db = getDatabase();
                if (data.image) {
                  set(ref(db, '/data/courses/points/' + index), {
                    content: data.content,
                    id: data.id,
                    name: data.name,
                    priceContent: data.priceContent,
                    tags: data.tags,
                    image: data.image,
                    youtube: url
                  });
                } else {
                  set(ref(db, '/data/courses/points/' + index), {
                    content: data.content,
                    id: data.id,
                    name: data.name,
                    priceContent: data.priceContent,
                    tags: data.tags,
                    youtube: url
                  });
                }
            } else {
                alert('Сессия устарела, перезайдите в профиль администратора')
            }
            // ...
        })
    }
}
