<template>
  <main class="main-page">
    <section class="hero">
      <div class="container hero__container">
        <div class="hero-info">
          <h2 class="hero__title">{{getDatabase?getDatabase.block1.title:'Загрузка...'}}</h2>
          <p class="hero__txt">{{getDatabase?getDatabase.block1.subtitle:'Загрузка...'}}</p>
        </div>
        <div class="hero-img">
          <div class="hero-img__item"> <img src="@/assets/images/hero/7-8/1.jpg" alt=""></div>
          <div class="hero-img__item"> <img src="@/assets/images/hero/7-8/2.jpg" alt=""></div>
        </div>
      </div>
    </section>
    <section class="how-old" id="ya1">
      <div class="container">
        <div class="how-old-block">
          <div class="how-old-block__img"> <img src="@/assets/images/how-old/1-min.png" alt=""></div>
          <div class="how-old-block__right">
            <div v-if="$route.path !== '/start'">
              <p class="how-old-block__p">Уже не важно кто вы. Важно, кем станут Ваши дети.</p>
              <h4 class="how-old-block__title">СКОЛЬКО ЛЕТ ВАШЕМУ РЕБЕНКУ?</h4>
            </div>
            <div v-else>
              <h4 class="how-old-block__title">Выбирайте программу<br/>под возраст вашего ребенка</h4>
            </div>
            <div class="how-old-block__btns">
              <router-link :to="{name: 'CartCourses', params: {id: '7-8'}}" class="how-old-block__btn" href=""><span> <span class="number">7</span> - <span class="number"> 8</span></span></router-link>
              <router-link :to="{name: 'CartCourses', params: {id: '9-11'}}" class="how-old-block__btn" href=""> <span> <span class="number">9 </span>-<span class="number"> 11</span></span></router-link>
              <router-link :to="{name: 'CartCourses', params: {id: '12-14'}}" class="how-old-block__btn" href=""> <span> <span class="number">12</span> - <span class="number">14</span></span></router-link>
              <router-link :to="{name: 'CartCourses', params: {id: '15-17'}}" class="how-old-block__btn" href=""> <span> <span class="number">15</span> - <span class="number">17</span></span></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  computed: {
    getDatabase () {
      return this.$root.database.home
    }
  },
  data () {
    return {
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
  .hero__title {
    @media (max-width: 900px) {
      font-size: 26px;
    }
  }
</style>
