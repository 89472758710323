import Vue from 'vue'
import App from './App.vue'
import store from "./store/store";
import router from './router'
import firebase from "firebase/compat";
// eslint-disable-next-line
import {getDatabase, onValue, ref, set} from "firebase/database";

firebase.initializeApp({
  apiKey: "AIzaSyD6qBgE3tWdi_5m0_YD5HPTxC2CNqPBbMM",
  authDomain: "kidstart-d4b10.firebaseapp.com",
  databaseURL: "https://kidstart-d4b10-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kidstart-d4b10",
  storageBucket: "kidstart-d4b10.appspot.com",
  messagingSenderId: "318657864169",
  appId: "1:318657864169:web:3eb66750d34c7448b0eb78",
  measurementId: "G-LC1F22S009"
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  data () {
    return {
      database: {},
      saveButton: false
    }
  },
  watch: {
    database: {
      // eslint-disable-next-line
      async handler (newVal, oldVal) {
        this.saveButton = true
      },
      deep: false
    }
  },
  methods: {
    async connectDB () {
      const db = await getDatabase();
      const starCountRef = await ref(db, '/');
      await onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        this.database = data.data
        // eslint-disable-next-line
        // updateStarCount(postElement, data);
      });
    },
    async writeUserData(data) {
      await firebase.auth().onAuthStateChanged(function(user) {
        console.log(user)
        if (user) {
          console.log(user)

          console.log('сохранение...')
          const db = getDatabase();
          set(ref(db, '/'), {
            data
          });
        } else {
          console.log('необходимо войти')
        }
        // ...
      })
    },
    checkLogin () {
    }
  },
  async created() {
    await firebase.auth().onAuthStateChanged
    await this.connectDB()
  },
  async mounted() {
    // await firebase.auth().onAuthStateChanged
    // await this.connectDB()
    // await this.checkLogin()
    // await this.writeUserData('asd', 'email', 'https://imagde.rasdu')
  },
  render: h => h(App)
}).$mount('#app')
