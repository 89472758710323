<template>
    <footer class="footer">
        <div class="container">
            <div class="footer-top"> <a class="footer-top__logo" href="#"><img src="@/assets/images/footer/logo.svg" alt=""></a>
                <div class="footer-top__assign"> <router-link to="/courses/0"> <span>Записаться на отдельный курс</span><img src="@/assets/images/footer/arrow.svg" alt=""></router-link >
                    <p>по развитию определенного качества ребенка и для решения проблемы</p>
                </div>
                <div class="footer-top__soc" v-if="getDatabase && isAUTH" style="display: flex">
                    <div><a class="footer-top__soc-lnk" :href="getDatabase.links.yt"><img src="@/assets/images/footer/soc/yt.svg" alt=""></a><input @focusout="writeDB($root.database)" v-model="getDatabase.links.yt" type="text" style="max-width: 100px" placeholder="Ссылка"></div>
                    <div><a class="footer-top__soc-lnk" :href="getDatabase.links.vk"><img src="@/assets/images/footer/soc/vk.svg" alt=""></a><input @focusout="writeDB($root.database)" v-model="getDatabase.links.vk" type="text" style="max-width: 100px" placeholder="Ссылка"></div>
                    <div><a class="footer-top__soc-lnk" :href="getDatabase.links.inst"><img src="@/assets/images/footer/soc/telegram_icon-icons.com_72055.png" alt=""></a><input @focusout="writeDB($root.database)" v-model="getDatabase.links.inst" type="text" style="max-width: 100px" placeholder="Ссылка"></div>
                    <div><a class="footer-top__soc-lnk" :href="getDatabase.links.facebook"><img src="@/assets/images/footer/soc/fb.svg" alt=""></a><input @focusout="writeDB($root.database)" v-model="getDatabase.links.facebook" type="text" style="max-width: 100px" placeholder="Ссылка"></div>
                    <div><a class="footer-top__soc-lnk" :href="getDatabase.links.viber"><img src="@/assets/images/footer/soc/viber.svg" alt=""></a><input @focusout="writeDB($root.database)" v-model="getDatabase.links.viber" type="text" style="max-width: 100px" placeholder="Ссылка"></div>
                </div>
                <div class="footer-top__soc" v-else-if="getDatabase">
                    <a class="footer-top__soc-lnk" v-if="getDatabase.links.yt.length" :href="getDatabase.links.yt"><img src="@/assets/images/footer/soc/yt.svg" alt=""></a>
                    <a class="footer-top__soc-lnk" v-if="getDatabase.links.vk.length" :href="getDatabase.links.vk"><img src="@/assets/images/footer/soc/vk.svg" alt=""></a>
                    <a class="footer-top__soc-lnk" v-if="getDatabase.links.inst.length" :href="getDatabase.links.inst"><img src="@/assets/images/footer/soc/telegram_icon-icons.com_72055.png" alt=""></a>
                    <a class="footer-top__soc-lnk" v-if="getDatabase.links.facebook.length" :href="getDatabase.links.facebook"><img src="@/assets/images/footer/soc/fb.svg" alt=""></a>
                    <a class="footer-top__soc-lnk" v-if="getDatabase.links.viber.length" :href="getDatabase.links.viber"><img src="@/assets/images/footer/soc/viber.svg" alt=""></a>
                </div>
            </div>
            <div class="footer-bottom">
                <ul class="footer-nav">
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 1}}" class="footer-nav__link" href="#"><span> Как это работает</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 2}}" class="footer-nav__link" href="#"><span>Как создавался курс</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 3}}" class="footer-nav__link" href="#"><span>Методологи курса</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 4}}" class="footer-nav__link" href="#"><img src="@/assets/images/footer/card-icon.svg" alt=""><span>Подарочные карты</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 9}}" class="footer-nav__link" href="#"><img src="@/assets/images/footer/free.svg" alt=""><span>Весь курс бесплатно</span></router-link></li>
                </ul>
                <ul class="footer-nav">
                    <li class="footer-nav__item"> <router-link :to="{name: 'CartCourses', params: {id: '7-8'}}" class="footer-nav__link" href="#"><span>Программа для детей <span class="number">7-8</span> лет</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'CartCourses', params: {id: '9-11'}}" class="footer-nav__link" href="#"><span>Программа для детей <span class="number">9-11</span> лет</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'CartCourses', params: {id: '12-14'}}" class="footer-nav__link" href="#"><span>Программа для детей <span class="number">12-14</span> лет</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'CartCourses', params: {id: '15-17'}}" class="footer-nav__link" href="#"><span>Программа для детей <span class="number">15-17</span> лет</span></router-link></li>
                    <li class="footer-nav__item"> <router-link to="/courses/0" class="footer-nav__link"><span>Отдельные уроки, программы и курсы</span></router-link></li>
                </ul>
                <ul class="footer-nav">
                    <li class="footer-nav__item"> <router-link v-show="1===2" :to="{name: 'Article', params: {id: 5}}" class="footer-nav__link" href="#"><span>Тест на лидерские качества</span></router-link></li>
                    <li class="footer-nav__item"> <router-link v-show="1===2" :to="{name: 'Article', params: {id: 6}}" class="footer-nav__link" href="#"><span>Проблемы детского бизнес-образования</span></router-link></li>
                  <li class="footer-nav__item"> <router-link :to="{name: 'Webinar', params: {id: 0}}" class="footer-nav__link" href="#">Бесплатные вебинары</router-link></li>
                  <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 33}}" class="footer-nav__link" href="#">Домашнее задание</router-link></li>
                  <li class="footer-nav__item"> <router-link :to="{name: 'BuzLager', params: {id: 1}}" class="footer-nav__link" href="#">Детский бизнес лагерь</router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 7}}" class="footer-nav__link" href="#"><span>Партнеры проекта</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Article', params: {id: 8}}" class="footer-nav__link" href="#"><span>Работа у нас</span></router-link></li>
                    <li class="footer-nav__item"> <router-link :to="{name: 'Articles'}" class="footer-nav__link" href="#"><span>Все статьи</span></router-link></li>
                </ul>
                <div v-if="getDatabase && !isAUTH" class="footer-data">
                    <p v-if="getDatabase.contact.name.length">{{ getDatabase.contact.name }}</p>
                    <p v-if="getDatabase.contact.inn.length">ИНН {{ getDatabase.contact.inn }}</p>
                    <p v-if="getDatabase.contact.ogrn.length">ОГРН {{ getDatabase.contact.ogrn }}</p>
                    <p v-if="getDatabase.contact.address.length">Адрес {{ getDatabase.contact.address }}</p>
                    <p v-if="getDatabase.contact.phone.length"><a :href="`tel:${getDatabase.contact.phone}`">Телефон {{ getDatabase.contact.phone }}</a></p>
                </div>
                <div v-if="getDatabase && isAUTH" class="footer-data footer-data-edit">
                  <div class="contacts-edit"><span>Название </span><input @focusout="getDatabase.contact.name = $event.target.value" :value="getDatabase.contact.name" type="text"></div>
                  <div class="contacts-edit"><span>ИНН </span><input @focusout="getDatabase.contact.inn = $event.target.value" :value="getDatabase.contact.inn" type="text"></div>
                  <div class="contacts-edit"><span>ОГРН </span><input @focusout="getDatabase.contact.ogrn = $event.target.value" :value="getDatabase.contact.ogrn" type="text"></div>
                  <div class="contacts-edit"><span>Адрес </span><input @focusout="getDatabase.contact.address = $event.target.value" :value="getDatabase.contact.address" type="text"></div>
                  <div class="contacts-edit"><span>Телефон </span><input @focusout="getDatabase.contact.phone = $event.target.value" :value="getDatabase.contact.phone" type="text"></div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import readDB from './read'
    import {mapGetters} from "vuex";
    export default {
        name: "Footer",
        computed: {
            getDatabase () {
                return this.$root.database.footer
            },
            ...mapGetters([
                'isAUTH'
            ]),
        },
        methods: {
            writeDB (data) {
                readDB.writeUserData(data)
            }
        }
    }
</script>

<style scoped lang="scss">
.footer-top__soc {
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.footer-data.footer-data-edit {
  .contacts-edit {
    display: flex;
    justify-content: space-between;
    width: 230px;
    span {
      font-size: 12px;
    }
    input {
      border: initial;
      border-bottom: 1px solid #ede7e7;
      font-size: 12px;
      background: white;
    }
  }
}
</style>
