<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
  import Header from "./components/Header";
  import Footer from "./components/Footer";
  import { mapActions } from 'vuex'
  export default {
    components: {Footer, Header},
    data () {
      return {
      }
    },
    methods: {
      ...mapActions([
              'CHECK_TO_TOKEN'
      ])
    },
    async mounted() {
      await this.CHECK_TO_TOKEN()
      document.body.classList.add('wrapper')
    }
  }
</script>

<style lang="scss">
  @import "assets/css/style.min.css";
  .edit-input {
    position: absolute;
  }
  .wait {
    width: 100%;
    background: #9974D5;
    color: white;
    text-align: center;
    padding: 50px;
    font-size: 30px;
  }
  .btn {
    cursor: pointer;
  }
</style>
