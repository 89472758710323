import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import pageNotFound from '../components/404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/start',
    name: 'Home-start',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login')
  },
  {
    path: '/cart-courses/:id',
    name: 'CartCourses',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '@/views/Cart-Course')
  },
  {
    path: '/buz-lager/:id',
    name: 'BuzLager',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '@/views/Buz-lager')
  },
  {
    path: '/courses/:id',
    name: 'Courses',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '@/views/Courses')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Articles')
  },
  {
    path: '/article/:id',
    name: 'Article',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '@/views/Article')
  },
  {
    path: '/webinar/:id',
    name: 'Webinar',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '@/views/Webinar')
  },
  {
    path: '/404',
    name: '404',
    component: pageNotFound
  }, {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  routes
})

export default router
