<template>
    <header class="header">
      <v-notification :messages="notification" :timeout="3000"/>
        <div class="container header__container">
            <div class="header__burger" @click="activeBurgerMenu = !activeBurgerMenu; addClassHtml()" :class="{'is-active': activeBurgerMenu}">
                <div class="header__burger-item"></div>
                <div class="header__burger-item"></div>
                <div class="header__burger-item"></div>
                <div class="header__burger-item"></div>
            </div><router-link style="cursor: pointer" tag="a" to="/" class="header__logo"><img src="@/assets/images/header/logo.svg" alt=""></router-link>
            <ul v-if="mobileToDesktop < 990" class="header__nav navigation">
                <li class="navigation__item" @click="activeBurgerMenu = !activeBurgerMenu; addClassHtml()"> <router-link :to="{name: 'Webinar', params: {id: 0}}" class="navigation__link">Бесплатный вебинар</router-link></li>
                <li class="navigation__item" @click="activeBurgerMenu = !activeBurgerMenu; addClassHtml()"> <router-link :to="{name: 'Article', params: {id: 1}}" class="navigation__link">Как проходит обучение</router-link></li>
                <li class="navigation__item" @click="activeBurgerMenu = !activeBurgerMenu; addClassHtml()"> <router-link :to="{name: 'Article', params: {id: 2}}">Как создавался курс</router-link></li>
            </ul>
            <ul v-else-if="mobileToDesktop > 990" class="header__nav navigation">
                <li class="navigation__item" ><router-link :to="{name: 'Webinar', params: {id: 0}}" class="navigation__link">Бесплатный вебинар</router-link></li>
                <li class="navigation__item" ><router-link :to="{name: 'Article', params: {id: 1}}" class="navigation__link">Как проходит обучение</router-link></li>
                <li class="navigation__item" ><router-link :to="{name: 'Article', params: {id: 2}}">Как создавался курс</router-link></li>
            </ul>
        </div>
        <div v-if="isAUTH" class="admin-block">
            <a @click="writeDB($root.database)" class="btn" href="#">Сохранить изменения</a>
            <a @click="LOGOUT" class="btn" href="#">Выйти</a>
        </div>
    </header>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import readDB from './read'
    import VNotification from "./notifications/v-notification";
    export default {
        name: "Header",
      components: {VNotification},
      data () {
            return {
              activeBurgerMenu: false,
              notification: []
            }
        },
        computed: {
            ...mapGetters([
                'isAUTH'
            ]),
            isSaveBnt () {
                return this.$root.saveButton && localStorage.user
            },
            mobileToDesktop () {
                return window.innerWidth
            }
        },
        methods: {
            ...mapActions([
                'LOGOUT'
            ]),
            writeDB (data) {
                readDB.writeUserData(data).then(() => {
                  this.notification.unshift(
                      { name: 'Сохранено!', icon: 'check_circle', id: 1 }
                  )
                }, () => {
                  this.notification.unshift(
                      { name: 'Ошибка!', icon: 'error', id: 2 }
                  )
                })
            },
            addClassHtml () {
                if (!this.activeBurgerMenu) {
                    document.getElementsByTagName('html')[0].classList.remove('mob-menu')
                } else {
                    document.getElementsByTagName('html')[0].classList.add('mob-menu')
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .admin-block {
        position: fixed; bottom: 10px; right: 10px;display: flex;
        .btn {
            opacity: .5;
          height: 42px;
          padding: 10px;
            &:hover {
                opacity: 1;
                background: #3ecf31;
                color: white;
            }
            &:first-child {
                margin-right: 10px;
            }
            &:last-child {
                margin-right: 10px;
                border: initial;
                background: #868686;
            }
        }
    }
</style>
